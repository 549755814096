.breadcrumbs{
	margin-bottom: 1rem;
	line-height: 1.5;
}

.product-area {
	min-height: calc(100vh - 180px);
}

.product-info-container {
	display: flex;
	flex: 1;
}

.product-image-container {
	display: flex;
	flex: 1;
	outline: 1px solid var(--root-border-color);
}

.vertical-image-list {
	display: flex;
    flex-direction: column;
	max-height: 60vh;
    flex: 1;
	overflow: scroll;
	padding: 2px;
	border-right: 1px solid var(--root-border-color);
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}

.vertical-image-list::-webkit-scrollbar{
    display: none;
}

.carousel-thumbnail {
	height: 100px;
	padding: 5px;
}

.carousel-thumbnail:hover {
	cursor: pointer;
	outline: 2px solid var(--primary-purple);
}

.vertical-image {
	width: 100%;
	height: 100%;
	object-fit: cover;	
}

.main-image-container {
	height: 60vh;
}

.main-image {
	width: 100%;
	height: 60vh;
	object-fit: contain;
	transform: scale(.9);
}

.product-title {
	font-size: 20px;
	font-weight: 700;
	letter-spacing: 1px;
}

.product-brand {
	color: var(--muted-gray);
	padding: .5rem 0;
}

.product-ratings-shortcut {
	width: fit-content;
    padding: 0.3rem 0.5rem 0.1rem 0.5rem;
    margin-top: 0.5rem;
	outline: 1px solid var(--root-border-color);
	border-radius: 4px;
}

.product-ratings-shortcut:hover {
	cursor: pointer;
	outline: 1px solid var(--black);
}

.product-ratings-shortcut-svg {
	margin: 0 .3rem;
}

.product-ratings-shortcut-svg > svg {
	width: 18px;
	fill: var(--primary-purple);
	stroke: transparent;
}

.product-price-section {
	margin-top: 1rem;
}

.product-price {
	color: #282c3f;
    margin-right: 12px;
	font-size: 20px;
	font-weight: 600;
	font-family: 'Prompt', sans-serif;
}

.product-old-price {
	margin-right: 12px;
}

.product-add-to-cart {
	color: white;
	margin-top: 1.5rem;
    padding: 0.6rem 1rem;
    width: 30%;
	border-radius: 4px;
}

.product-add-to-cart:hover {
	cursor: pointer;
	background-color: var(--primary-purple);
	color: white;
}

.variant-group-container {
	margin-top: 2rem;
}

.variant-pill-container {
	padding: .5rem 1rem;
	outline: 1px solid var(--root-border-color);
	margin-right: 1rem;
	font-weight: 600;
}

.variant-pill-container:hover {
	cursor: pointer;
	color: var(--primary-purple);
	outline: 1px solid var(--primary-purple);
}

.product-description {
	margin-top: 2rem;
}

.product-description-data {
	margin-top: 1rem;
}

.product-description-icon > svg {
	width: 20px;
	stroke-width: 1px;
	margin: 0 .5rem;
}

.product-sticky-container {
	position: sticky;
	top: 90px;
}

.product-specifications-container {
	margin-top: 2rem;
	border: 1px solid var(--root-border-color);
}

.product-specifications-heading {
	margin: 0;
	margin-bottom: 1rem;
	padding: 1rem;
	border-bottom: 1px solid var(--root-border-color);
}

.product-specifications {
	margin: 0 1rem;
}

.product-specification-title {
	margin: 0;
}

.product-specification-table{
	margin-bottom: .5rem;
	width: 100%;
}

.product-specification-row  td {
	padding: .5rem;
}

.specification-name {
	color: var(--muted-gray);
	width: 15%;
}