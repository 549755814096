:root {
	--container-gutter-sm: 20px;
	--container-gutter-md: 30px;
	--container-gutter: 60px;
	--root-border-color: rgb(227, 227, 227);
	--muted-gray: #717482;
	--primary-purple: rgb(114, 117, 210);
	--light-purple: #babbf8;
	--black: rgb(68, 68, 68);
}

::placeholder {
	color: var(--primary-purple);
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: var(--primary-purple);
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: var(--primary-purple);
}

html {
 scroll-behavior: smooth;
}

a {
	text-decoration: none;
}

h3, h4 {
	margin: 0px;
}

input, button {
	font-family: 'Assistant';
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	margin: 0;
}

input[type=checkbox] {
	margin-right: 1em;
}

div[role=button], button {
	cursor: pointer;
}

.icon {
	vertical-align: middle;
	margin-right: 14px;
}

.Count-down {
	display: flex;
	justify-content: center;
	margin-top: calc(70px);
	padding: 10px var(--container-gutter-md);
	font-size: 16px;
	color: var(--black);
	background-image: linear-gradient(90deg,#f9f9ff,#dee8ff);
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 4%);
	letter-spacing: 1px;
}

.Navbar {
	display: flex;
	position: fixed;
	top: 0;
	width: 100%;
	background-color: white;
	z-index: 3;
	justify-content: center;
	padding: 10px var(--container-gutter);
	border-bottom: 1px solid var(--root-border-color);
}

.Nav-categories {
	display: flex;
}

.Nav-link {
	padding: 0px 20px;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
    letter-spacing: 1px;
	color: var(--black);
}

.Search-container {
	display: flex;
	align-items: center;
	margin-left: 1rem;
	width: 350px;
}

.Search-bar {
	font-size: 14px;
	height: 15px;
	width: 100%;
	line-height: 24px;
	color: #696e79;
	padding: 10px 20px;
	outline: 0;
	border: 1px solid #bfc0e5;
	border-radius: 4px;
	transition: border .2s ease-out;
}

.Search-bar:focus {
	border: 2px solid #a3a5ee;
}

.Search-bar::placeholder {
	font-family: 'Assistant';
}

.Nav-cart {
	cursor: pointer;
}

.Cart-label {
	width: 10px;
	height: 10px;
	align-items: center;
	justify-content: center;
	display: inline-flex;
	margin-left: 5px;
	padding: 8px;
	border-radius: 50%;
	font-size: 12px;
	background-color: var(--primary-purple);
	color: white;
}

.Cart-preview-container {
	visibility: hidden;
}

.Cart-preview-container[open] {
	visibility: visible;
}

.Cart-preview {
 height: 100vh;
 top: 0;
 background-color: white;
 position: fixed;
 right: 0;
 z-index: 10;
 width: 98vw;
 max-width: 400px;
 border: 1px solid var(--root-border-color);
 transition: transform 0.6s cubic-bezier(0.75, 0, 0.175, 1), visibility 0.6s cubic-bezier(0.75, 0, 0.175, 1);
 transform: translateX(100%);
}

.Cart-preview-container[open] > .Cart-preview {
	transform: translateX(0);
}

.Cart-overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(255,255,255);
	transition: visibility 0.6s ease-in-out, opacity 0.6s ease-in-out;
	visibility: hidden;
	opacity:  0;
	cursor: pointer;
}

.Cart-preview-container[open] > .Cart-overlay {
	transform: translateX(0);
	visibility: visible;
	opacity: .9;
}

.Cart-header {
	display: flex;
	align-items: center;
	height: 70px;
	font-family: 'Archivo Black';
	font-size: 16px;
	font-weight: 500;
	word-spacing: 5px;
	padding: 0 var(--container-gutter-md);
	border-bottom: 1px solid var(--root-border-color);
}

.Cart-title {
	display: flex;
	width: 50%;
	justify-content: flex-start;
	align-items: center;
}

.Cart-close {
	width: 50%;
	display: flex;
	justify-content: right;
}

.Cart-items-container {
	padding: .5rem var(--container-gutter-md);
	border-bottom: 1px solid var(--root-border-color);
	height: 75%;
	overflow-y: scroll;
}

.Cart-slider-empty-state {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.Cart-item {
	display: flex;
	padding: 20px 0 ;
}

.Cart-image {
	height: 100px;
	width: 100px;
	border-radius: 4px;
	overflow: hidden;
}

.Cart-image-actual {
	height: 100px;
	object-fit: contain;
	width: 100%;
}

.Cart-item-name {
	display: flex;
	flex-direction: column;
	max-width: 50%;
	padding-left: 30px;
}

.Cart-item-name > h3 {
	color: var(--black) !important;
}

.Counter-container {
	padding: .5rem 0;
}

.Counter {
	display: flex;
    justify-content: center;
    align-items: center;
	width: fit-content;
	border: 1px solid var(--root-border-color);
	border-radius: 1rem;
}

.Counter > input {
	line-height: 20px;
	border: none;
	text-align: center;
	color: var(--primary-purple);
}

.Counter > button {
	height: 28px;
	background-color: white;
	color: var(--primary-purple);
	border: none;
	border-radius: 1rem;
	padding: 0px 0.7rem;
	font-size: 18px;
}

.Cart-price-container {
	padding: 0 1rem;
	flex: 1;
    text-align: end;
}

.Checkout-CTA {
	margin-top: 1rem;
	width: 100%;
    display: flex;
    justify-content: center;
}

.Checkout-btn {
	padding: .5rem 1rem;
	width: 80%;
	background-color: var(--primary-purple);
	border-radius: 1rem;
	border: none;
	color: white;
	font-size: 16px;
}

.Shop-area {
	display: flex;
	width: 100%;
	margin-bottom: 2em;
}

.Shop-area-header {
	display: flex;
	height: 6vh;
	align-items: center;
	padding: 10px 0;
}

#Filters-container {
	min-width: 20%;
	min-height: 350px;
}

.Filters-header {
	padding-left: var(--container-gutter-md);
	font-size: 18px;
	font-family: 'Archivo Black';
	line-height: 1.25;
	text-transform: uppercase;
	color: var(--black);
}

.Product-section {
	min-width: 80%;
}

.Sort-by-header {
	justify-content: flex-end;
	padding-right: var(--container-gutter-md);
}

.Sort-by {
	padding: 0.4em 0.6em;
	outline: 1px solid var(--root-border-color);
	border-radius: 4px;
}

#Sort-methods {
	-webkit-appearance: none;
	border: none;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	cursor: inherit;
	outline: none;
	line-height: inherit;
}

#Product-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	row-gap: 2em;
}

@media (min-width: 1400px) {
	.Cart-preview { max-width: 500px; }
}

@media (max-width: 1200px) {
	#Product-grid { grid-template-columns: repeat(4, 1fr); }

	.Nav-categories { display: none; }

}

@media (max-width: 900px) {
	.Product-section {
		min-width: 70%;
	}

	#Product-grid {
		grid-template-columns: repeat(3, minmax(150px, 1fr));
	}

	#Filters-container { min-width: 30%; }
}

@media (max-width: 600px) {
	:root {
		--container-gutter-md: 10px;
		--container-gutter: 10px;
	}

	.Shop-area { display: block; }

	#Filters-container { display: none; }

	#Product-grid { grid-template-columns: repeat(2, minmax(150px, 1fr)); row-gap: .5em;}

	.Product-section {
		min-width: calc(100% -(var(--container-gutter-md) * 2));
		padding: 0 var(--container-gutter-md);
	}

	.Count-down { margin-top: 120px; }

	.Cart-icon {
		width: 30px;
		height: 30px;
	}

	.Cart-price-container {
		padding: 0 0.8rem;
	}

	.Sort-by-header {
		display: none;
	}

	.Cart-image {
		height: 50px;
		width: 50px;
	}

	.Cart-image-actual {height: 50px;}

}

@media (max-width: 420px) {
	.Counter {
		padding: .2em;
	}

	.Counter > button {
		padding: 0 .4em;
	}
}

@media (max-width: 300px) {
	#Product-grid {
		grid-template-columns: repeat(1, minmax(150px, 1fr));
	}
}

.Item-image {
	position: relative;
	padding-bottom: 1rem;
	overflow: hidden;

}

.Item-image-actual {
	height: 230px;
	width: 100%;
	object-fit: contain;
}

.Item-ratings {
	position: absolute;
	bottom: 10px;
	padding: 4px 6px 4px 10px;
	border-radius: 2px;
	background-color: rgb(255, 255, 255, 0.8);
}

.overlay {
 display: none;
 position: absolute;
 top: 0;
 height: 100%;
 width: 100%;
 background-color: rgba(255,255,255,.8);
}

.Item-image:hover .overlay{
	display: flex;
	justify-content: center;
	align-items: center;
}

.Item-meta-details {
	padding: 0 10px;
}

.Item-price-details {
	padding-top: 10px;
}

.card-link {
	color: var(--black);
}
.card-link:hover {
	text-decoration: underline;
}

/* Common Styles */

.purple-text {
	color: var(--primary-purple);
}

.black-text {
	color: var(--black);
}
.orange-text {
	color: orange;
}

.green-text {
	color: green;
}

.text-muted {
	color: #717482;
	font-weight: 400;
}

.m-0 {
	margin: 0px;
}

.p-0 {
	padding: 0px;
}

.pt-p5 {
	padding-top: .5rem;
}

.pt-1 {
	padding-top: 1rem;
}

.pt-2 {
	padding-top: 2rem;
}

.pb-5 {
	padding-bottom: 5px;
}

.f-small {
	font-size: 12px;
}

.f-medium {
	font-size: 14px;
}

.f-normal {
	font-size: 16px;
}

.f-large {
	font-size: 18px;
}

.f-xl {
	font-size: 20px;
}

.f-2xl {
	font-size: 22px;
}

.f-4xl {
	font-size: 24px;
}

.f-5xl {
	font-size: 26px;
}

.wght-500 {
	font-weight: 500;
}

.wght-600 {
	font-weight: 600;
}

.wght-700 {
	font-weight: 700;
}

.w-50 {
	width: 50%;
}

.d-flex {
	display: flex;
}

.svg-container {
	vertical-align: middle;
    display: inline-block;
}
/* -x- */

.Mobile-micro-cart-btn {
	position: absolute;
	top: 10px;
	right: 20px;
	height: 36px;
    padding: 0.5rem 0.5rem 0.15rem 0.5rem;
    border-radius: 50%;
	box-shadow: 0 2px 6px rgb(17 43 66 / 8%), 0 1px 4px rgb(17 43 66 / 10%);
	background-color: rgb(255, 255, 255, 0.9);
	overflow: hidden;
	border: none;
	color: var(--primary-purple);
}

.feather-shopping-cart {
	position: relative;
	stroke-width: 1px;
}

.Mobile-micro-cart-btn.clicked {
	color: white;
	background: forestgreen;
}

.Micro-cart-btn {
	border: none;
	color: white;
	padding: 10px;
	border-radius: 4px;
	width: 60%;
	overflow: hidden;
}
.Micro-add-to-cart {
	border: none;
	background: linear-gradient(90deg, rgba(138,140,209,1) 13%, rgba(104,72,190,1) 92%);
	border-bottom: 3px solid var(--primary-purple);
}

.Micro-add-to-cart:hover {
	border-bottom: 2px solid var(--primary-purple);
}

.Added-to-cart {
	color: white;
	background: linear-gradient(90deg, rgba(138,182,47,1) 13%, rgba(57,138,37,1) 92%);
	border-bottom: 3px solid rgba(138,182,47,1);
}

.cart-animate {
	animation: wiggle 0.5s linear;
}

@keyframes wiggle {
	8%,
	41% {
		transform: translateX(-10px);
	}
	25%,
	58% {
		transform: translate(10px);
	}
	75% {
		transform: translate(-5px);
	}
	92% {
		transform: translate(5px);
	}
	0%,
	100% {
		transform: translate(0);
	}
}

.New-label {
	background-color: rgba(255, 131, 0, .8);
	color: white;
	text-transform: uppercase;
	position: absolute;
	top: 2em;
	padding: 2px 6px;
	line-height: 16px;
	font-size: 11px;
}

.New-label::after {
	content: "";
	position: absolute;
	border-color: rgba(255, 131, 0, .5) transparent rgba(255, 131, 0, .5) rgba(255, 131, 0, .8);
	border-style: solid;
	border-width: 10px;
	left: 100%;
	width: 0;
	height: 0;
	top: 0;
}

.Footer-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 2em 0;
	background-image: linear-gradient(90deg,#f9f9ff,#dee8ff);
}

.Footer-container > div {
	margin: 0 2em;
}

.product-data-container {
	padding: 1.8rem 0 0 1.5rem;
}