.Filter-row-container {
    margin: 0 20px 0 var(--container-gutter-md);
    font-size: 14px;
}

.Filter-row-header {
    display: flex;
    border-top: 1px solid var(--root-border-color);
    padding: 20px 0;
}

.Filter-row-header-title {
    width: 50%;
}

.Filter-row-header-icon {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
}

.Filter-row-header-icon > svg > .vertical-line {
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(0.47, 0, 0.88, 0.69);
    transform-origin: center;
}

.Filter-row-header-icon[open] > svg > .vertical-line {
    transform: scale(0);
    transition: transform 0.3s cubic-bezier(0.47, 0, 0.88, 0.69);
}

.Filter-row-header-icon > svg {
    float: right;
}

.Filter-row-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease-out, padding 0.3s ease-out;
}

.Filter-row-body[open] {
    max-height: 100vh;
    padding-bottom: 20px;
    transition: max-height 0.6s ease-out, padding 0.3s ease-out;
}

.Filter-row-body-item {
    display: flex;
    color: var(--muted-gray);
}

.Mobile-filter-toggler-container {
    display: none;
    position: fixed;
    top: 70px;
    grid-template-columns: repeat(1, 100%);
    width: 100%;
    height: 50px;
    z-index: 2;
    border: 1px solid var(--root-border-color);
}

.Mobile-filter-toggler {
    display: none;
    align-items: center;
    justify-content: center;
    padding: var(--container-gutter-md);
    outline: 1px solid var(--root-border-color);
    border-top: transparent;
    background-color: white;
}

#Filters-drawer-mobile {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 70px;
    z-index: 5;
    border-top: 1px solid var(--root-border-color);
    transform: translateX(-100%);
}

@media (max-width: 600px) {
    .Mobile-filter-toggler-container { display: grid; }
    .Mobile-filter-toggler { display: flex; }

    #Filters-drawer-mobile {
        visibility: hidden;
        background-color: white;
        width: 100%;
        height: 100%;
        transition: all 0.6s cubic-bezier(0.75, 0, 0.175, 1);
    }

    #Filters-drawer-mobile[open] {
        visibility: visible;
        transform: translateX(0);
        transition: all 0.6s cubic-bezier(0.75, 0, 0.175, 1);
    }
}

.Clear-filters {
    margin-right: 20px;
    color: var(--primary-purple);
}

.Filters-CTA {
    display: flex;
    justify-content: center;
    width: 100%;
}

.Apply-filters-btn {
    width: 80%;
    padding: .5rem 1rem;
    background-color: var(--primary-purple);
	border-radius: 1rem;
	border: none;
	color: white;
	font-size: 16px;
}